import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M2370 4503 c-63 -10 -67 -13 -96 -73 -15 -33 -31 -60 -35 -60 -3 0
-23 23 -45 50 l-39 51 -54 -5 c-29 -3 -55 -7 -58 -10 -3 -3 15 -27 40 -53 76
-82 109 -122 105 -130 -2 -5 -25 -51 -52 -103 -48 -94 -48 -94 -62 -68 l-14
26 -95 -34 c-53 -19 -100 -34 -104 -34 -8 0 -31 52 -31 71 0 4 40 22 89 39
l89 32 -10 29 c-5 16 -14 31 -18 34 -4 3 -45 -8 -91 -25 -46 -16 -86 -26 -90
-22 -3 4 -11 22 -17 40 -10 30 -9 34 11 41 136 48 177 63 177 66 0 1 -5 17
-11 34 -8 23 -16 31 -28 28 -29 -9 -274 -97 -277 -99 -3 -4 126 -362 135 -370
4 -5 74 17 156 47 83 31 172 58 198 62 48 6 48 6 84 77 l36 71 52 -59 53 -59
57 8 56 7 -46 51 c-25 29 -62 71 -82 95 l-37 44 52 104 c29 58 46 103 39 103
-6 -1 -23 -3 -37 -6z"/>
<path d="M2480 4472 l0 -39 61 -7 61 -7 -6 -42 c-3 -23 -8 -94 -12 -158 l-7
-116 43 -7 c23 -4 45 -4 48 -1 4 3 11 74 17 158 l10 152 63 3 62 3 0 34 c0 41
-1 41 -150 50 -58 3 -124 8 -147 12 l-43 5 0 -40z"/>
<path d="M2905 4458 c-6 -19 -105 -369 -105 -372 0 -5 92 -28 95 -24 1 2 8 25
15 52 13 48 13 48 48 42 47 -7 52 -14 66 -81 l11 -57 55 -17 c30 -10 68 -25
85 -33 28 -15 31 -15 62 9 l32 25 78 -44 78 -44 -3 -39 c-3 -37 0 -42 38 -63
23 -13 44 -22 46 -20 5 6 45 399 42 415 -2 7 -22 25 -44 38 l-42 25 -38 -31
c-22 -16 -52 -41 -69 -54 -16 -12 -70 -55 -120 -94 -49 -39 -93 -71 -97 -71
-4 0 -12 19 -18 43 -6 23 -13 49 -16 58 -3 10 8 21 32 32 68 33 96 155 47 209
-26 29 -80 54 -163 77 -39 11 -80 23 -91 26 -11 4 -21 1 -24 -7z m190 -128
c35 -29 24 -93 -19 -109 -23 -9 -102 3 -119 19 -8 7 13 96 28 120 6 10 88 -12
110 -30z m361 -197 c-3 -10 -8 -44 -12 -75 -3 -32 -10 -58 -14 -58 -4 0 -29
12 -54 26 -45 25 -46 27 -29 43 93 83 119 98 109 64z"/>
<path d="M1340 4143 c-18 -7 -200 -133 -200 -138 0 -8 223 -310 231 -313 10
-4 79 36 79 46 0 4 -14 25 -30 46 -17 22 -30 42 -30 45 0 3 15 16 34 29 l34
23 51 -25 c63 -32 67 -32 114 3 l37 28 -47 21 c-27 11 -58 23 -70 26 -20 6
-21 12 -16 55 10 85 -52 162 -130 160 -23 0 -49 -3 -57 -6z m75 -98 c37 -36
32 -67 -17 -109 -23 -20 -47 -36 -54 -36 -10 0 -74 88 -74 102 0 10 92 68 107
68 8 0 25 -11 38 -25z"/>
<path d="M3739 3852 l-144 -141 75 -76 c41 -41 93 -84 116 -95 86 -42 188 -14
248 69 27 38 31 51 31 110 0 64 -2 71 -42 122 -23 30 -64 76 -91 103 l-50 48
-143 -140z m208 -40 c18 -24 33 -55 33 -69 0 -68 -65 -133 -134 -133 -29 0
-46 9 -82 42 l-45 42 83 83 c46 46 90 82 98 81 8 -2 29 -23 47 -46z"/>
<path d="M949 3814 c-52 -58 -96 -111 -98 -117 -2 -6 3 -15 11 -21 8 -6 53
-45 99 -87 46 -43 104 -95 129 -118 25 -22 48 -41 51 -41 3 0 19 15 34 33 16
17 62 69 104 115 l74 83 -27 27 -27 27 -70 -77 c-39 -43 -76 -78 -83 -78 -7 0
-23 11 -36 25 l-23 25 63 71 63 71 -27 26 -26 27 -61 -68 c-33 -37 -66 -67
-73 -67 -7 0 -23 11 -36 25 l-23 24 71 78 71 78 -21 23 c-12 12 -27 22 -33 22
-6 0 -54 -48 -106 -106z"/>
<path d="M785 3561 c-11 -5 -32 -22 -46 -37 -29 -31 -125 -192 -118 -198 2 -2
63 -38 136 -80 73 -42 147 -85 165 -96 18 -11 36 -20 39 -20 3 0 15 18 27 39
l23 40 -46 26 c-25 15 -45 31 -45 35 0 4 14 34 30 65 47 89 37 154 -31 204
-34 26 -101 36 -134 22z m68 -92 c43 -19 51 -54 25 -106 -12 -24 -25 -46 -29
-49 -10 -6 -100 46 -106 62 -6 16 32 80 55 94 22 12 26 12 55 -1z"/>
<path d="M4053 3492 c-112 -14 -203 -29 -203 -33 0 -5 10 -24 22 -45 20 -34
24 -36 60 -31 38 5 39 4 74 -51 58 -90 60 -96 35 -131 -21 -29 -21 -32 -5 -63
29 -57 35 -61 52 -36 9 13 67 92 129 176 l114 153 -28 45 c-15 24 -32 44 -38
43 -5 -1 -101 -13 -212 -27z m125 -118 c-17 -24 -39 -51 -49 -60 -16 -14 -19
-12 -44 31 -15 25 -25 49 -22 52 9 8 130 30 140 26 4 -3 -7 -24 -25 -49z"/>
<path d="M3138 3489 c-25 -5 -79 -23 -120 -40 -40 -17 -86 -35 -103 -41 -16
-7 -34 -15 -40 -20 -5 -5 -25 -20 -44 -33 -23 -17 -29 -25 -18 -25 19 0 22
-13 7 -41 -7 -13 -7 -19 0 -19 14 0 13 -63 -2 -78 -10 -10 -10 -15 2 -22 13
-8 12 -10 -2 -10 -10 0 -18 -5 -18 -11 0 -6 9 -8 23 -4 l22 6 -22 -20 c-32
-27 -29 -34 7 -16 52 27 32 7 -25 -26 -30 -17 -55 -33 -55 -35 0 -4 48 18 180
84 146 73 242 95 384 90 132 -5 173 -21 252 -103 120 -123 125 -259 23 -669
-21 -87 -39 -162 -39 -165 0 -8 178 -215 189 -219 12 -4 131 489 151 625 7 45
10 137 8 205 -3 101 -9 137 -31 201 -63 183 -186 303 -377 368 -74 25 -269 35
-352 18z m-278 -149 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5
0 9 -4 9 -10z m46 -192 c-10 -6 -22 -6 -26 -2 -5 5 3 10 18 11 22 1 24 0 8 -9z"/>
<path d="M1785 3469 c-11 -17 3 -24 48 -23 29 1 36 -1 22 -6 -17 -7 -16 -8 10
-13 29 -4 29 -5 -5 -6 -19 0 -42 -1 -50 -2 -8 -1 -24 2 -35 5 -11 4 -7 0 8 -9
27 -16 28 -18 25 -93 l-3 -77 150 -31 c83 -18 198 -44 257 -59 l107 -27 68 58
c103 89 148 132 143 137 -7 6 -334 84 -475 114 -66 13 -147 26 -180 27 -33 1
-66 5 -72 9 -6 4 -15 2 -18 -4z m75 -65 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14
10 5 8 35 3 35 -6z"/>
<path d="M2775 3320 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2745 3290 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M2680 3250 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4390 3227 c-14 -6 -27 -12 -29 -13 -1 -2 5 -26 14 -54 9 -29 13 -55
9 -60 -5 -4 -73 -29 -152 -55 -133 -44 -142 -48 -136 -68 15 -48 24 -67 32
-67 4 0 68 20 142 45 74 25 140 45 146 45 7 0 20 -22 29 -50 9 -27 20 -52 23
-54 4 -2 22 2 39 9 l32 14 -53 157 c-29 87 -57 159 -62 160 -5 2 -20 -2 -34
-9z"/>
<path d="M550 3180 c-6 -22 -9 -44 -6 -49 3 -4 55 -23 117 -42 127 -39 159
-62 159 -112 0 -44 -38 -87 -77 -87 -15 0 -76 16 -136 36 -59 20 -114 34 -121
31 -7 -3 -18 -23 -23 -45 l-10 -39 96 -31 c155 -50 177 -55 215 -48 93 18 160
139 134 242 -17 69 -56 98 -198 144 -69 22 -128 40 -132 40 -4 0 -12 -18 -18
-40z"/>
<path d="M4160 2783 c0 -48 8 -59 47 -67 38 -9 40 -13 48 -112 l7 -79 -31 -18
c-27 -16 -31 -24 -31 -62 0 -51 11 -61 42 -41 13 8 46 27 74 43 28 15 99 55
157 88 l107 60 0 52 0 52 -102 31 c-57 17 -146 44 -200 61 -53 16 -101 29
-107 29 -6 0 -11 -17 -11 -37z m250 -120 c33 -9 60 -20 60 -24 0 -9 -127 -75
-133 -69 -2 3 -7 29 -9 58 -6 60 -6 60 82 35z"/>
<path d="M462 2758 c-44 -55 -60 -188 -28 -246 17 -33 66 -62 104 -62 35 0 74
42 111 119 31 65 40 76 61 76 22 0 25 -5 28 -42 3 -41 -24 -113 -48 -128 -18
-11 -10 -30 21 -48 38 -21 46 -16 79 50 29 57 39 158 20 203 -19 46 -56 70
-106 70 -55 0 -77 -22 -124 -119 -30 -64 -37 -72 -60 -69 -22 3 -25 8 -24 39
1 20 11 58 24 83 12 26 21 48 19 50 -20 15 -55 36 -60 36 -4 0 -11 -6 -17 -12z"/>
<path d="M2470 2300 c14 -4 21 -8 15 -9 -6 -1 -9 -66 -8 -178 2 -176 2 -178
-20 -185 -15 -4 -8 -6 23 -7 97 -1 182 71 195 168 5 38 2 59 -16 100 -34 80
-100 123 -184 120 -29 -1 -30 -1 -5 -9z"/>
<path d="M1255 2088 c-7 -22 -55 -219 -71 -293 -45 -204 -50 -235 -43 -247 6
-9 9 -3 10 17 0 26 2 27 9 10 l9 -20 0 20 c1 15 3 17 10 7 7 -12 14 -11 41 3
17 9 41 18 53 21 12 2 25 10 29 19 5 8 22 15 38 15 22 0 30 5 33 23 2 12 3 0
1 -28 -3 -48 -3 -49 7 -15 39 136 69 249 69 261 0 8 -14 30 -31 49 -16 19 -56
65 -87 102 -59 69 -70 77 -77 56z m-88 -480 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m30 -20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M1760 1917 c0 -14 52 -119 57 -114 2 3 -9 31 -26 63 -16 33 -30 55
-31 51z"/>
<path d="M1736 1758 c10 -14 21 -24 23 -22 3 3 -6 15 -18 27 l-24 22 19 -27z"/>
<path d="M1821 1756 c-6 -16 -9 -37 -6 -47 4 -11 2 -19 -4 -19 -6 0 -11 5 -11
10 0 6 -5 10 -10 10 -9 0 41 -85 60 -100 13 -11 138 -167 155 -192 38 -60 30
-24 -11 55 -25 45 -51 96 -60 112 -108 213 -101 203 -113 171z m36 -68 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3545 1037 c-11 -12 -18 -26 -16 -32 1 -5 0 -7 -4 -3 -4 4 -36 -9
-72 -29 -118 -65 -255 -56 -631 41 l-133 34 -32 -26 c-109 -89 -187 -166 -175
-172 15 -9 315 -83 403 -100 33 -7 83 -18 110 -25 103 -26 289 -40 371 -27 71
11 223 57 234 71 3 4 19 15 35 26 42 26 135 116 135 129 0 6 -5 4 -10 -4 -8
-13 -10 -13 -10 1 0 18 -19 29 -60 36 -38 7 -101 46 -96 60 3 7 -1 10 -10 6
-7 -3 -14 -9 -14 -14 0 -5 -7 -9 -15 -9 -14 0 -13 3 1 25 22 33 14 41 -11 12z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
